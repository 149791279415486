/*
 Do not use @7 in production, use a complete version like x.x.x, see website for latest version:
 https://www.algolia.com/doc/guides/building-search-ui/installation/react/#load-the-style
 */
@import url("https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/algolia-min.css");

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

#root {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 70px; /* height of your footer */
}